/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Reduce margin in Material tooltip
mat-tooltip-component .mat-tooltip{
    margin: 5px;
}

// Message boxes
.message-box {
    padding: 16px;
    background: #607D8B;
    border-left: 6px solid #37474F;
    color: rgba(255, 255, 255, 1);

    &.error {
        background: #EF5350;
        border-left-color: #B71C1C;
    }

    &.warning {
        background: #FFECB3;
        border-left-color: #FFC107;
        color: rgba(0, 0, 0, 0.87);
    }

    &.success {
        background: #4CAF50;
        border-left-color: #2E7D32;
    }

    &.info {
        background: #B3E5FC;
        border-left-color: #03A9F4;
        color: rgba(0, 0, 0, 0.87);
    }
}

// Fix for ngx-gauge label
ngx-gauge {
    .reading-label {
        left: 0;
    }
}

// Necessary so that ngx-material-timepicker
// shows up over Angular modal
.timepicker-backdrop-overlay{
    z-index: 1000 !important;
}
.timepicker-overlay{
    z-index: 1000 !important;

    // Make timepicker modal rounded
    .timepicker {
        @apply rounded-xl overflow-auto #{!important};
    }
}
